import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import { graphql } from "gatsby"
import styled from "styled-components"
import { BigMobileFont, SmallMobileFont } from "../components/typography"
import LastCTASection from "../components/lastCTASection"
import ListSection from "../components/listSection"
import JobOfferTile from "../components/jobOfferTile"
import JobOfferForm from "../components/jobOfferForm"
import BaseImg from "../components/baseImg"
import ArrowMore from "../components/arrowMore"
import { useAnimatedBorder } from "../helpers"

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: calc(100vh - 180px);
  margin-bottom: 60px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const HeroImage = styled(BaseImg)`
  margin: 0 40px 0 0;
  position: relative;
  z-index: -1;
  max-height: 100%;
  width: 40%;
  @media (max-width: 768px) {
    width: 60%;
    margin: 0;
  }
`

const JobOfferGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 52px 32px;
  justify-items: center;
  margin: 72px 0 120px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const HeroContent = styled.div`
  margin: 0 auto 0 -120px;
  padding: 54px 36px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  max-width: 780px;
  @media (max-width: 900px) {
    margin: 0 auto;
  }
`

const Career = ({ data }) => {
  const [scale, boxRef] = useAnimatedBorder()
  const {
    listSectionTitle,
    openPositionsSectionTitle,
    lastCTASectionTitle,
    lastCTASectionText,
    lastCTASectionButtonUrl,
    lastCTASectionButtonText,
    formSectionTitle,
    formSectionText,
    firstSectionTitle,
    firstSectionText,
    firstSectionImage,
    list,
  } = data.wordpressPage.cmb2.metabox_career

  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/career"
      />
      <Container>
        <HeroSection>
          <HeroImage localFile={firstSectionImage.localFile} />
          <HeroContent>
            <h1 style={{ marginBottom: "0.75rem" }}>
              <BigMobileFont desktopFontSize="2.5rem">
                {firstSectionTitle}
              </BigMobileFont>
            </h1>
            <SmallMobileFont>{firstSectionText}</SmallMobileFont>
          </HeroContent>
          <div style={{ position: "absolute", bottom: "0", right: "0" }}>
            <ArrowMore />
          </div>
        </HeroSection>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CenterBorderedHeader
            size="small"
            style={{ display: "inline-block" }}
          >
            <BigMobileFont desktopFontSize="2.5rem">
              {openPositionsSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
        </div>
        <JobOfferGrid ref={boxRef}>
          {data.allWordpressWpJoboffer.nodes.map(
            ({ title, path, cmb2 }, index) => (
              <JobOfferTile
                key={path}
                title={title}
                path={path}
                salary={cmb2.metabox_post_job_offer.salary}
                scale={scale}
                orientation={
                  index % 4 === 0 || (index + 1) % 4 === 0
                    ? "vertical"
                    : "horizontal"
                }
              />
            )
          )}
        </JobOfferGrid>
      </Container>
      <ListSection
        title={listSectionTitle}
        items={list}
        position="center"
        headerPosition="left"
        headerSize="small"
      />
      <Container size="medium">
        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {formSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <SmallMobileFont
          style={{ display: "block", textAlign: "center", marginTop: "40px" }}
        >
          {formSectionText}
        </SmallMobileFont>
        <JobOfferForm />
      </Container>

      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default Career

export const query = graphql`
  query CareerPageQuery {
    allWordpressWpJoboffer {
      nodes {
        title
        path
        cmb2 {
          metabox_post_job_offer {
            salary
          }
        }
      }
    }
    wordpressPage(template: { eq: "page-templates/page-career.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_career {
          listSectionTitle
          openPositionsSectionTitle
          lastCTASectionTitle
          lastCTASectionText
          lastCTASectionButtonUrl
          lastCTASectionButtonText
          formSectionTitle
          formSectionText
          firstSectionTitle
          firstSectionText
          list {
            buttonText
            buttonUrl
            contentText
            contentTitle
            title
          }
          firstSectionImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
